<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div class="col-md-9">
                    <h3 class="font-weight-bold heading"><b>Google Drive </b></h3>
                </div>
                <div class="col-md-3">
                    <form [formGroup]="searchForm">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>Custom Date</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate placeholder="From" formControlName="startDate">
                                <input matEndDate placeholder="To" formControlName="endDate">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker>
                                <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply
                                        (click)="googleDriveReport()">Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                        </mat-form-field>

                    </form>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-xl-4 col-sm-6 col-12 pe-2 mb-3" *ngFor="let item of googleDriveSlabs">
                        <div class="card pe-auto card-bg">
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="text-center text-heading mb-2">
                                        <strong
                                            [ngClass]="item.searchText && schedulerLogs.length >0 ?'pointer ':''">{{item.name}}</strong>
                                    </div>
                                    <div class="d-flex justify-content-between align-self-center">
                                        <div>
                                            <strong> {{item.count}}</strong>
                                        </div>
                                        <div class="tickets">
                                            <i [ngClass]="item.icon" class="fs-6"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="font-weight-bold sub-heading">Schedular Log Report <Button *ngIf="messages.length>0"
                                class="exl-btn float-end ms-2 excel mb-4" (click)="exportReportInExcel()">Export To
                                Excel</Button>
                        </h4>
                        <p class="text-center fs-5 mt-4" *ngIf="messages.length==0">No Data found !</p>
                        <div class="col-12 table-responsive  ps-0 pe-0" *ngIf="messages.length>0">
                            <table class="table table-bordered  fixed-columns-table" #tableData>
                                <thead class=" thead-header">
                                    <tr>
                                        <th scope="col ">S No.</th>
                                        <th scope="col ">Message</th>
                                        <th scope="col " *ngFor="let date of dates">
                                            {{date}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of messages; let i = index">
                                        <td>{{i + 1}}</td>
                                        <td class="d-inline-block text-truncate" style="width: 300px;"
                                            ngbTooltip="{{data | titlecase}}" placement="top">{{data}}</td>
                                        <td *ngFor="let date of dates" (click)="showDetails(data,date)"
                                            [ngClass]="showData(data,date) >0 ? 'table-pointer' :''">
                                            {{showData(data,date)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 mt-4 " *ngIf="isShowDetails">
                <div class="sub-heading mb-3 ms-2 me-2">Schedular Log List <span class="float-end ms-2 excel"
                        (click)="excelGoogleLogsData()" *ngIf="schedulerLogs.length>0">Export To Excel</span>

                </div>
                <div class="card">
                    <div class="card-body">
                        <div>
                            <span class="records ">{{pagination}}</span>
                        </div>
                        <div class="table-responsive mt-2" *ngIf="schedulerLogs.length>0">
                            <table class="table table-bordered detail-table" id="google-drive-log">
                                <thead class="thead-header">
                                    <tr>
                                        <th scope="col">Sub Id</th>
                                        <th scope="col">File Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Executed Date</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Deleted Date</th>
                                        <th scope="col">Deleted Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let schedulerLog of schedulerLogs | paginate:config let i=index;">
                                        <td>
                                            <a class="sub-link" [routerLink]="['/subscriptions-details']"
                                                [queryParams]="{ subId: schedulerLog?.subCode }">
                                                {{schedulerLog?.subCode}}
                                            </a>
                                        </td>
                                        <td>{{schedulerLog?.fileName}}</td>
                                        <td>{{schedulerLog?.status | titlecase}}</td>
                                        <td>{{schedulerLog?.createdAt |date :'dd MMM YY HH:mm'}}</td>
                                        <td>{{schedulerLog?.message |titlecase}}</td>
                                        <td>{{schedulerLog?.deleteDate |date :'dd MMM YY HH:mm'}}</td>
                                        <td>{{schedulerLog?.deleteMessage |titlecase}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-4 float-end me-4" *ngIf="schedulerLogs.length>0">
                            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>