import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginWithAdminComponent } from './login-with-admin/login-with-admin.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Auth2Guard } from './_helpers/auth2.guard';
import { VerifyPaymentComponent } from './verify-payment/verify-payment.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SlaComponent } from './sla/sla.component';
const routes: Routes = [
  {
    path: 'loginWithAdmin',
    component: LoginWithAdminComponent
    // redirectTo: 'dashboard'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [Auth2Guard]
  },
  {
    path: 'verify-payment',
    component: VerifyPaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
 
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'sla',
    component: SlaComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
    canActivate: [AuthGuard],

  },
  { path: '**', redirectTo: 'login' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
