import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveService {

  constructor(
    private http: HttpClient
  ) { }

  googleDriveReport(search: any) {
    return this.http.get(`${environment.apiURL}/partner/google/drive?search=${search}`)
  }

  exportToExcelDriveLog(search: string) {
    return this.http.get(`${environment.apiURL}/partner/google/drive/export?search=${search}`)
  }

  googleDriveDetails(page: number, limit: number, search: any) {
    return this.http.get(`${environment.apiURL}/partner/google/drive/details?limit=${limit}&page=${page}&search=${search}`)
  }

}