import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { GoogleDriveService } from 'src/app/services/google-drive.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-google-drive',
  templateUrl: './google-drive.component.html',
  styleUrls: ['./google-drive.component.scss']
})
export class GoogleDriveComponent implements OnInit {
  @ViewChild('tableData', { static: false }) tableData: ElementRef | undefined;
  isShowDetails: boolean = false
  searchText: string = ''
  schedulerLogs: any[] = []
  totalCount: number = 0
  page: number = 1
  logsCount: any = []
  messages: any = []
  dates: any = []
  selectedDate: string = ''
  selectedMessage: string = ''

  config = {
    itemsPerPage: 30,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  googleDriveSlabs = [
    { name: 'Auth Attempt', label: 'authAttempt', count: 0, icon: 'bi bi-dropbox', searchText: '' },
    { name: 'Auth Completed', label: 'authCompleted', count: 0, icon: 'bi bi-bag-check-fill', searchText: '' },
    { name: 'Schedular Add', label: 'noOfScheduler', count: 0, icon: 'bi bi-patch-plus-fill', searchText: '' },
    { name: 'Success Schedular Log ', label: 'noOfSuccessLog', count: 0, icon: 'bi bi-check-square-fill', searchText: 'success' },
    { name: 'Failed Schedular Log ', label: 'noOfFailedLog', count: 0, icon: 'bi bi-bag-x-fill', searchText: 'failed' },
    { name: 'Delete Schedular Log ', label: 'noOfDeletedLog', count: 0, icon: 'bi bi-trash3-fill', searchText: 'deleted' },
  ]
  searchForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  })
  constructor(
    private authService: AuthService,
    private googleDriveService: GoogleDriveService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate)
    this.googleDriveReport()
  }

  get pagination() {
    const paginationData = this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems)
    return paginationData
  }

  googleDriveReport() {
    const payload = {
      startDate: this.searchForm.controls.startDate.value,
      endDate: this.searchForm.controls.endDate.value
    }

    this.googleDriveService.googleDriveReport(JSON.stringify(payload)).subscribe((res: any) => {
      if (res.success) {
        this.logsCount = res.logsCount
        this.messages = res.messages
        this.dates = res.dates
        this.googleDriveSlabs.map((obj: any) => {
          obj.count = res[obj.label]
        })
      }
    }, (err) => {
      this.toastrService.error(err.error.message)
    })
  }

  showData(data: string, date: string): number {
    let count = 0
    this.logsCount.forEach((obj: any) => {
      if (obj.message === data && obj.date === date) {
        count = obj.count
      }
    });
    return count
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.showDetails(this.selectedMessage, this.selectedDate)
  }

  excelGoogleLogsData() {
    const serachPayload = {
      date: this.selectedDate,
      message: this.selectedMessage
    }
    this.googleDriveService.exportToExcelDriveLog(JSON.stringify(serachPayload)).subscribe((res: any) => {
      if (res.success) {
        this.exportexcel(res.schedulerLogs)
      }
    })
  }

  exportexcel(schedulerLogs: any): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(schedulerLogs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'GoogleDriveLogDetails.xlsx');
  }

  exportReportInExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tableData?.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'GooglDriveReport.xlsx');
  }

  showDetails(data: string, date: string) {
    this.selectedDate = date;
    this.selectedMessage = data;
    const serachPayload = {
      date: date,
      message: data
    }
    this.googleDriveService.googleDriveDetails(this.config.currentPage, this.config.itemsPerPage, JSON.stringify(serachPayload)).subscribe((res: any) => {
      if (res.success) {
        this.isShowDetails = true
        this.schedulerLogs = res.schedulerLogs;
        this.config.currentPage = res?.totalSchedulerLogs?.pages || 1;
        this.config.totalItems = res?.totalSchedulerLogs?.total || 0;
      }
    }, (err) => {
      this.isShowDetails = false
      this.schedulerLogs = []
      this.config.currentPage = 1;
      this.config.totalItems = 0;
      this.toastrService.error(err.error.message)
    })
  }
}
