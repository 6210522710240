import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SubFilterPipe } from '../customPipe/sub-filter.pipe';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import * as Highcharts from 'highcharts';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SalesmanService } from 'src/app/services/salesman.service';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [SubFilterPipe]
})

export class DashboardComponent implements OnInit {
  @ViewChild("selectedRenewalTime") selectedRenewalTime!: ElementRef
  @ViewChild('timeLineSelect') timeLineSelect!: MatSelect;
  @ViewChild('multiSelect1') multiSelect1!: MatSelect;
  @ViewChild('multiSelect2') multiSelect2!: MatSelect;
  constructor(
    private dashboardService: DashboardService,
    private walletService: WalletService,
    private salesmanService: SalesmanService,
    private router: Router,
    private profileService: ProfileService,
    private toasts: ToastrService,
    private userService: UserService,
    private authService: AuthService
  ) { }

  isShow: any;
  userName = localStorage.getItem("userName")
  activeSectionForPaid: string = 'day';
  activeSectionForTrial: string = 'day';
  balanceAmount: any;
  profileDatas: any
  logo: any = '';
  isShowCategory: boolean = false
  isShowDowngradeCategory: boolean = false
  isBillDetailField: boolean = false;
  domainName: any
  allManagerSelected = false;
  allSalesmanSelected = false
  salesmanData: any
  trialToPaidSubDetails: any
  expiredSubDetails: any
  totalTrialSubDetails: any
  totalTrialGraph: any
  liveTrialDetails: any
  trialExpiredGraph: any
  trialToPaidGraph: any
  liveTrialGraph: any
  filteredMonths: any[] = []
  filteredMonth: any[] = []
  riskyCustomer: any
  riskyGraphData: any[] = []
  newPaidSub: any
  totalPaidSub: any
  monthlyRevReport: any
  subData: any
  newSubData: any
  newPaidId: any
  chartSelectedType: any
  chartStartDate: any
  chartEndDate: any
  relationshipManagerData: any
  Highcharts: typeof Highcharts = Highcharts;
  paidSubTrendOption: Highcharts.Options = {}
  riskyCustomerChartOption: Highcharts.Options = {}
  trialReportChartOption: Highcharts.Options = {}
  updateFromInput: boolean = true
  objFromInput: any = {}
  chartType: string = "column"
  paidChartType: string = "column"

  subscriptionSummary: any[] = [
    { label: 'Total Renewals', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
    { label: 'Renewed', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
    {
      label: 'Upgrades',
      hasSubCategories: true,
      isExpanded: false,
      data: [],
      iconClass: 'bi-graph-up-arrow text-success ms-2',
      subCategories: [
        { id: '3.1', label: 'User', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
        { id: '3.2', label: 'Price', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
        { id: '3.3', label: 'Plan', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' }
      ]
    },
    {
      label: 'Downgrade',
      hasSubCategories: true,
      isExpanded: false,
      data: [],
      iconClass: 'bi-graph-down-arrow text-danger ms-2',
      subCategories: [
        { id: '4.1', label: 'User', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' },
        { id: '4.2', label: 'Price', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' },
        { id: '4.3', label: 'Plan', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' }
      ]
    },
    { label: 'Upcoming Renewals', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
    { label: 'Expired', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' },
    { label: 'Deleted', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' }
  ];



  AllWeekMonthYear = [
    { value: 'this_week', label: 'This Week' },
    { value: 'last_week', label: 'Last Week' },
    { value: 'next_week', label: 'Next Week' },
    { value: 'this_month', label: 'This Month' },
    { value: 'last_month', label: 'Last Month' },
    { value: 'next_month', label: 'Next Month' },
    { value: 'this_year', label: 'This Year' },
    { value: 'last_year', label: 'Last Year' },
    { value: 'next_year', label: 'Next Year' },
  ]

  chartAllMonthYear = [
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'quarter', label: 'Quarter' },
    { value: 'year', label: 'Year' },
  ]

  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]

  searchForm = new FormGroup(
    {
      relationshipManagerIds: new FormControl([]),
      salesmanIds: new FormControl([]),
      timeLine: new FormControl('this_month'),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      searchText: new FormControl('')
    }
  )

  ngOnInit() {
    this.userService.isShowValue$.subscribe((value) => {
      this.isShow = value;
    });

    this.profileData();

    this.walletService.walletAmountSubject.subscribe((res) => {
      this.balanceAmount = res;
    });

    this.walletService.logoSubject.subscribe((res: any) => {
      this.logo = res;
    });

    const initialStartDate = moment().startOf('month').toISOString();
    const initialEndDate = moment().endOf('month').toISOString();

    this.searchForm.controls.startDate.setValue(initialStartDate);
    this.searchForm.controls.endDate.setValue(initialEndDate);

    this.searchForm.controls.timeLine.valueChanges.subscribe((value: any) => {
      this.dateRange(value)
    })

    this.submitAll();
    this.profileDetails();
    this.salesmanList();

  }



  profileData() {
    this.walletService.showWalletAmount()
  }


  get searchText() {
    return this.searchForm.controls.searchText.value || '';
  }


  submitAll() {
    this.subscriptionReportData()
    this.subscriptionTrialReport()
    this.renewalSummaryReport()
    this.riskyCustomersReport()
    this.paidCustomersSummary()
  }




  // function for getting total paid and new paid subscriptions
  subscriptionReportData(): void {
    const payload = {
      startDate: this.searchForm.controls.startDate.value ?? '',
      endDate: this.searchForm.controls.endDate.value ?? '',
      salesmanIds: this.searchForm.controls.salesmanIds.value,
      relationshipManagerIds: this.searchForm.controls.relationshipManagerIds.value
    }
    this.dashboardService.getSubscriptionReport(payload).subscribe((res: any) => {
      if (res.success) {
        this.subData = res?.totalPaidSub;
        this.newSubData = res?.newPaidSub;
      } else {
        this.subData = {};
        this.newSubData = {};
      }
    })
  }




  // function for getting subscription trial report & Trial Summary Trend with line chart
  subscriptionTrialReport(): void {
    const interval = this.activeSectionForTrial
    const payload = {
      startDate: this.searchForm.controls.startDate.value ?? '',
      endDate: this.searchForm.controls.endDate.value ?? '',
      salesmanIds: this.searchForm.controls.salesmanIds.value,
      relationshipManagerIds: this.searchForm.controls.relationshipManagerIds.value,
      interval: interval
    }

    this.dashboardService.getSubscriptionTrialReport(payload).subscribe((res: any) => {
      if (res.success) {

        this.totalTrialSubDetails = res?.totalTrialSub;
        this.expiredSubDetails = res?.expiredSub;
        this.trialToPaidSubDetails = res?.trialToPaidSub;
        this.liveTrialDetails = res?.liveTrialSub;

        const totalTrialGraph = res.totalTrialSubGraph.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        const trialExpiredGraph = res.expiredSubGraph.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        const trialToPaidGraph = res.trialToPaidSubGraph.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        this.setChartVariableForTrial(totalTrialGraph, trialExpiredGraph, trialToPaidGraph, interval)
      }
    })
  }

  setChartVariableForTrial(totalTrialSubData: any, expiredSubData: any, trialToPaidSubData: any, interval: any) {
    const objFromInput: any = {}
    objFromInput['credits'] = false
    objFromInput['chart'] = { type: this.chartType };
    objFromInput['legend'] = { align: 'center', verticalAlign: 'top', lineHeight: 0, padding: 0 };
    objFromInput['title'] = { text: '' };
    objFromInput['subtitle'] = { text: `<b>Trial Summary Trend<b/>` };
    objFromInput['yAxis'] = {
      title: {
        text: 'No Of Subscriptions',
      },
    };

    objFromInput['xAxis'] = {
      type: 'datetime',
      labels: {
        format: interval === 'month' ? '{value: %b %Y}' : interval === 'year' ? '{value: %Y}' : '{value:%e %b %Y}'
      },
      tickPositioner: function () {
        return this.series[0].xData;
      },


    };

    objFromInput['tooltip'] = {
      formatter: function () {
        const formattedDate = Highcharts.dateFormat('%e %b, %Y', this.x)
        const point = this.point; // Extract the point data
        return `<b>${point.series.name}</b><br/><b>No Of Users :</b> ${point.noOfUsers}<br/><b>No Of Sub :</b> ${point.y.toLocaleString('en-IN', { maximumFractionDigits: 0 })}<br/><b>Value :</b> ${point.amount} <br/><b>date :</b> ${formattedDate}`;
      }
    };
    objFromInput['plotOptions'] = {
      series: {
        borderRadius: 3
      }
    },
      objFromInput['series'] = [
        {
          name: 'Trial Signups',
          data: totalTrialSubData.map((data: any) => {
            const x = data[0];
            const y = data[1];
            const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
            const amount = data[3].toLocaleString('en-IN', { maximumFractionDigits: 0 })
            return {
              x,
              y,
              amount,
              noOfUsers
            };
          }),
        },
        {
          name: 'Trial Expired',
          data: expiredSubData.map((data: any) => {
            const x = data[0];
            const y = data[1];
            const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
            const amount = data[3].toLocaleString('en-IN', { maximumFractionDigits: 0 })
            return {
              x,
              y,
              amount,
              noOfUsers
            };
          }),
        },
        {
          name: 'Trial To Paid',
          data: trialToPaidSubData.map((data: any) => {
            const x = data[0];
            const y = data[1];
            const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
            const amount = data[3].toLocaleString('en-IN', { maximumFractionDigits: 0 })
            return {
              x,
              y,
              amount,
              noOfUsers
            };
          }),
        },
      ];
    objFromInput['colors'] = ['#4B49AC', '#E0E0E0', '#64ABFF']
    this.trialReportChartOption = objFromInput
    Highcharts.chart('trialReportChart', this.trialReportChartOption)
    this.updateFromInput = true
  }


  renewalSummaryReport(): void {
    const payload = {
      startDate: this.searchForm.controls.startDate.value ?? '',
      endDate: this.searchForm.controls.endDate.value ?? '',
      salesmanIds: this.searchForm.controls.salesmanIds.value,
      relationshipManagerIds: this.searchForm.controls.relationshipManagerIds.value
    }

    this.dashboardService.getSubscriptionRenewalSummary(payload).subscribe((res: any) => {
      if (res.success) {

        this.updateSubscriptionSummary(res);
      }
    })
  }

  updateSubscriptionSummary(res: any) {
    const mapping: any = {
      'Total Renewals': res?.totalRenewals,
      'Renewed': res?.renewed,
      'Upgrades': res?.upgrade,
      'Downgrade': res?.downgrade,
      'Upcoming Renewals': res?.upcomingRenewals,
      'Expired': res?.expired,
      'Deleted': res?.deleted
    };

    const upgradeMapping: any = {
      'User': res?.userUpgradeCategories,
      'Price': res?.priceUpgradeCategories,
      'Plan': res?.planUpgradeCategories
    };

    const downgradeMapping: any = {
      'User': res?.userDowngradeCategories,
      'Price': res?.priceDowngradeCategories,
      'Plan': res?.planDowngradeCategories
    };

    this.subscriptionSummary.forEach((category) => {
      category.data = mapping[category.label] || [];
      if (category.hasSubCategories && category.subCategories) {
        category.subCategories.forEach((subCategory: any) => {
          if (category.label === 'Upgrades') {
            subCategory.data = upgradeMapping[subCategory.label] ?? [];
          } else if (category.label === 'Downgrade') {
            subCategory.data = downgradeMapping[subCategory.label] ?? [];
          }
        });
      }
    });
  }


  // function for getting Risky Customers Report with doughnut chart
  riskyCustomersReport(): void {
    const payload = {
      startDate: this.searchForm.controls.startDate.value ?? '',
      endDate: this.searchForm.controls.endDate.value ?? '',
      salesmanIds: this.searchForm.controls.salesmanIds.value,
      relationshipManagerIds: this.searchForm.controls.relationshipManagerIds.value
    }
    this.dashboardService.getRiskySubscriptionReport(payload).subscribe((res: any) => {
      if (res.success) {
        this.riskyCustomer = res?.riskySub
      } else {
        this.riskyCustomer = {}
      }
      this.objFromInput['credits'] = { enabled: false }
      this.objFromInput['chart'] = { type: 'pie', plotShadow: false, plotBorderWidth: 0, plotBackgroundColor: '', height: 300 }
      this.objFromInput['title'] = { text: '' };
      this.objFromInput['tooltip'] = { pointFormat: '<b>{point.name}</b>: {point.y}', outside: true };
      this.objFromInput['series'] = [
        {
          data: [
            {
              name: "subcriptions",
              y: this.riskyCustomer.totalSub
            },
            {
              name: "customers",
              y: this.riskyCustomer.totalUsers
            }
          ]
        }
      ]
      this.objFromInput['plotOptions'] = {
        series: {
          dataLabels: [{ enabled: false }],
          cursor: 'pointer',
          showInLegend: true
        },
        pie: {
          innerSize: '70%'
        }
      }
      this.objFromInput['colors'] = ['#4B49AC', '#f33a3a']
      this.riskyCustomerChartOption = this.objFromInput
      Highcharts.chart('riskyCustomerChart', this.riskyCustomerChartOption)
      this.updateFromInput = true
    })
  }



  // function for getting Live Subscriptions Summary Trend with bar chart
  paidCustomersSummary(): void {
    const interval = this.activeSectionForPaid
    const payload = {
      startDate: this.searchForm.controls.startDate.value ?? '',
      endDate: this.searchForm.controls.endDate.value ?? '',
      salesmanIds: this.searchForm.controls.salesmanIds.value,
      relationshipManagerIds: this.searchForm.controls.relationshipManagerIds.value,
      interval: interval
    }

    this.dashboardService.getPaidSubscriptionReport(payload).subscribe((res: any) => {
      if (res.success) {
        const newPaidSubData = res.newPaidSubGraphData.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })

        const churnSubData = res.churnSubGraphData.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        this.setChartVariableForPaidSubTrend(newPaidSubData, churnSubData, interval)

      }
    });
  }

  setChartVariableForPaidSubTrend(newPaidSubData: any, churnSubData: any, interval: any) {
    const objFromInput: any = {}
    objFromInput['credits'] = false
    objFromInput['chart'] = { type: this.paidChartType };
    objFromInput['legend'] = { align: 'center', verticalAlign: 'top', lineHeight: 0, padding: 0 };
    objFromInput['title'] = { text: '' };
    objFromInput['subtitle'] = { text: `<b>New VS Churn Subscription</b>` };
    objFromInput['yAxis'] = {
      title: {
        text: 'Amount',
      },
    };

    objFromInput['xAxis'] = {
      type: 'datetime',
      title: {
        text: '',
      },
      labels: {
        format: interval === 'month' ? '{value: %b %Y}' : interval === 'year' ? '{value: %Y}' : '{value:%e %b %Y}'
      },
      tickPositioner: function () {
        return this.series[0].xData;
      },
    };
    objFromInput['plotOptions'] = {
      series: {
        borderRadius: 3
      }
    },
      objFromInput['tooltip'] = {
        formatter: function () {
          const formattedDate = Highcharts.dateFormat('%e %b, %Y', this.x)
          const point = this.point; // Extract the point data
          return `<b>${point.series.name}</b><br/><b>No Of Users : </b>${point.noOfUsers}<br/><b>No Of Sub : </b>${point.noOfSub}<br/><b>Value : </b>${point.y.toLocaleString('en-IN', { maximumFractionDigits: 0 })} <br/><b>date : </b>${formattedDate}`;
        }
      };
    objFromInput['series'] = [
      {
        name: 'New Paid Subscription',
        data: newPaidSubData.map((data: any) => {
          const x = data[0];
          const noOfSub = data[1].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const y = data[3];
          return {
            x,
            y,
            noOfSub,
            noOfUsers
          };
        }),
      },
      {
        name: 'Churn Subscription',
        data: churnSubData.map((data: any) => {
          const x = data[0];
          const noOfSub = data[1].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const y = data[3];
          return {
            x,
            y,
            noOfSub,
            noOfUsers
          };
        }),
      },
    ];
    this.paidSubTrendOption = objFromInput
    Highcharts.chart('paidSubTrendChart', this.paidSubTrendOption)
    this.updateFromInput = true
  }



  toggleDaysMonthYear(section: string, id: number) {
    if (id == 2) {
      this.activeSectionForPaid = section
      this.paidCustomersSummary()
    } else if (id == 1) {
      this.activeSectionForTrial = section
      this.subscriptionTrialReport()
    }
  }

  toggleChartType(graph: string, id: number) {
    if (id == 1) {
      this.chartType = graph
      this.trialReportChartOption['chart'] = { type: graph }
      Highcharts.chart('trialReportChart', this.trialReportChartOption)
      this.updateFromInput = true
    }
    else if (id == 2) {
      this.paidChartType = graph
      this.paidSubTrendOption['chart'] = { type: graph }
      Highcharts.chart('paidSubTrendChart', this.paidSubTrendOption)
      this.updateFromInput = true
    }
  }





  // function for get details of a partner in a dashboard according to date.
  dateRange(selectedType: string) {
    let startDate: any;
    let endDate: any;
    if (selectedType == "this_week") {
      startDate = moment().startOf('week').add(1, 'day').toISOString();
      endDate = moment().endOf('week').add(1, 'day').toISOString();
    } else if (selectedType == "last_week") {
      startDate = moment().startOf('week').subtract(1, 'weeks').add(1, 'day').toISOString();
      endDate = moment().endOf('week').subtract(1, 'weeks').add(1, 'day').toISOString();
    } else if (selectedType == "next_week") {
      startDate = moment().startOf('week').add(1, 'weeks').add(1, 'day').toISOString();
      endDate = moment().endOf('week').add(1, 'weeks').add(1, 'day').toISOString();
    } else if (selectedType == "this_month") {
      startDate = moment().startOf('month').toISOString();
      endDate = moment().endOf('month').toISOString();
    } else if (selectedType == "last_month") {
      startDate = moment().startOf('month').subtract(1, 'months').toISOString();
      endDate = moment().endOf('month').subtract(1, 'months').toISOString();
    } else if (selectedType == "next_month") {
      startDate = moment().startOf('month').add(1, 'months').toISOString();
      endDate = moment().endOf('month').add(1, 'months').toISOString();
    } else if (selectedType == "this_year") {
      startDate = moment().startOf('year').toISOString();
      endDate = moment().endOf('year').toISOString();
    } else if (selectedType == "last_year") {
      startDate = moment().startOf('year').subtract(1, 'year').toISOString();
      endDate = moment().endOf('year').subtract(1, 'year').toISOString();
    } else if (selectedType == "next_year") {
      startDate = moment().startOf('year').add(1, 'year').toISOString();
      endDate = moment().endOf('year').add(1, 'year').toISOString();
    }

    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate);
    this.submitAll()
  }




  // function for sending a selected data to view in subscription-list route
  sendDataToSubscription(subId: string) {
    console.log(subId)
    if (subId.length > 0) {
      this.dashboardService.setData(subId);
      this.router.navigate(['subscriptions-list']);
    }
  }




  // function for get the all details of a list
  salesmanList() {
    this.salesmanService.salesmanList().subscribe((res: any) => {
      if (res.success) {
        this.salesmanData = res.salesPerson;
        this.relationshipManagerData = res.relationShipManager
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  clickableData(subType: string) {
    const selectedValue = this.selectedRenewalTime?.nativeElement?.value;
    if (subType != 'renewal') {
      this.router.navigate(['subscriptions-list'], { queryParams: { subType: subType } })
    } else {
      this.router.navigate(['subscriptions-list'], { queryParams: { subType: subType, timeInterval: selectedValue } })
    }
  }




  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allSalesmanSelected) {
          item.select();
        } else if (id === 2 && this.allManagerSelected) {
          item.select();
        } else {
          item.deselect();
        }
      });
    }
  }




  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;

    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 1) {
      this.allSalesmanSelected = newStatus;
    } else if (id === 2) {
      this.allManagerSelected = newStatus;
    }
  }




  deselectAll(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1) {
          item.deselect();
          this.allSalesmanSelected = false
        } else if (id === 2) {
          this.allManagerSelected = false
          item.deselect();
        }
      });
    }
  }



  profileDetails() {
    this.profileService.profileDetails().subscribe((res: any) => {
      if (res.success) {
        this.profileDatas = res?.partner?.billing;
        this.domainName = res?.partner?.branding
      }
    })
  }




  get userType() {
    return this.authService.userType()
  }

  get isSalesman() {
    return this.authService.isSalesman()
  }

  payment() {
    //   if (this.isBillDetailField) {
    //     this.router.navigate(['/payment'])
    //   } else {
    //     this.toastr.warning('Please fill billing details first.')
    //   }
    // }
    this.router.navigate(['/payment'])

  }
  getCategory() {
    this.isShowCategory = !this.isShowCategory
  }
}