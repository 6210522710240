<div class="container-fluid ps-0 pe-0">
    <div class="main-panel">
        <div class="row">
            <div class="col-md-12 p-4">
                <h6 class="heading text-center mb-2">Service Level Agreement (SLA)</h6>
                <p class="fs-6">Service Level Agreement (SLA) for Cloud Services by Comhard Technologies Private
                    Limited: </p>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>1. INTRODUCTION </b></h6>
                    <p class="fs-6">Comhard Technologies Private Limited ("Comhard") is the provider of Cloud services
                        ("Services") to its valued customers ("Customers"). This SLA governs the service levels for the
                        provision of these Services and must be reviewed alongside Comhard's Terms of Service ("Terms").
                    </p>
                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>2. DEFINITIONS </b></h6>
                    <div class="ms-5">
                        <p class="fs-6"><b>a. </b>Uptime (UT): The percentage of hours in a calendar month during which
                            the Services are accessible to Customers. UT = 100% – Downtime (DT). </p>
                        <p class="fs-6"><b>b. </b>Downtime (DT): The percentage of hours in a billed month during which
                            specific Services offered by Comhard were inaccessible to Customers. Downtime is any unexpected, unplanned, or unexcused service interruption that occurs within Comhard’s responsibility.  </p>
                        <p class="fs-6"><b>c. </b>Service Time (ST): The total hours in the calendar month during which
                            Comhard provides Services. </p>
                        <p class="fs-6"><b>d. </b>Emergency Maintenance (EM): Maintenance conducted under circumstances
                            that pose a threat to system, equipment, network, or facility integrity required for Service
                            delivery. Comhard will make reasonable efforts to notify Customers about emergency
                            maintenance in advance. </p>
                        <p class="fs-6"><b>e. </b>Excused Unavailability (EU): The number of hours in any month during which Comhard may perform troubleshooting or upgrades to enhance Services, with prior notification to Customers. Emergency Maintenance are considered part of Excused Unavailability. Excused Unavailability (EU) is not counted as downtime and it is not eligible for rebates. </p>
                        <p class="fs-6"><b>f. </b>Planned Downtime (PD): The number of hours in any billed month during
                            which downtime is scheduled by Comhard for maintenance activities. Customers receive advance
                            notice of Planned Downtime. </p>
                        <p class="fs-6"><b>g. </b>Rebates:  Compensation provided to Customers as outlined in Clause 6 of this SLA. </p>
                        <p class="fs-6"><b>h. </b>Force Majeure Event: Extraordinary events beyond Comhard's control
                            that may impact Service provision, such as natural disasters, epidemics, cyber threats,
                            government actions, and acts of nature. </p>
                    </div>
                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>3. UPTIME POLICY </b></h6>
                    <p class="text-black fs-6">Should Uptime during a month fall below 99.5%, Comhard will issue Rebates
                        to Customers as follows: </p>
                    <div class="ms-5">
                        <p class="fs-6"><b>a. </b>99.5% or higher: No Service Extension </p>
                        <p class="fs-6"><b>b. </b>99.5% to 99%: Service Extension for 1 day beyond the Service period
                        </p>
                        <p class="fs-6"><b>c. </b>99% to 98%: Service Extension for 2 days beyond the Service period</p>
                        <p class="fs-6"><b>d. </b>98% to 97%: Service Extension for 3 days beyond the Service period
                        </p>
                        <p class="fs-6"><b>e. </b>Less than 97%: Service Extension for 3+n days, where n is equal to 97
                            minus Uptime. </p>
                    </div>
                    <p class="text-black fs-6">Alternatively, Comhard may offer equivalent credits or discounts at its
                        discretion in the next billing cycle. </p>
                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>4. Severity, Impact, and Urgency-Based SLA </b></h6>
                    <p class="text-black fs-6">Severity Levels </p>
                    <p class="fs-6">Comhard defines severity based on the impact and urgency of the issue. This
                        framework ensures that critical issues are addressed with the highest priority. </p>
                    <h6 class="fs-6 mb-2"><b>4.1 Impact: </b></h6>

                    <div class="ms-5">
                        <p class="fs-6"><b>a. Extensive (1) </b> </p>
                        <p class="fs-6"><b>b. Significant (2)</b></p>
                        <p class="fs-6"><b>c. Moderate (3)</b></p>
                        <p class="fs-6"><b>d. Minor (4) </b></p>

                    </div>
                    <h6 class="fs-6 mb-2"><b>4.2 Urgency: </b></h6>
                    <div class="ms-5">
                        <p class="fs-6"><b>a. Critical (1): </b>Affects an entire service with no workaround available.
                        </p>
                        <p class="fs-6"><b>b.High (2): </b>Affects a key function of business operations with limited or
                            temporary workarounds. </p>
                        <p class="fs-6"><b>c. Medium (3): </b>Moderately affects a user's role, with available
                            workarounds. </p>
                        <p class="fs-6"><b>d. Low (4): </b>Has minimal impact on services; workarounds are easily
                            available. </p>
                    </div>
                    <h6 class="fs-6 mb-2"><b>4.3 Priority Matrix: </b></h6>
                    <p class="fs-6">The Priority Matrix combines the Impact and Urgency of issues to determine the
                        appropriate priority, ensuring that the most critical issues are addressed first. The First
                        Response Time (FRT) and Resolution Time for each priority level will be calculated based on
                        Comhard's business working hours. </p>
                    <h6 class="fs-6 mb-2"><b>Priority Matrix according to Impact and Urgency: </b></h6>
                    <table class="w-100 mb-3">
                        <thead>
                            <tr>
                                <th>Impact/Urgency </th>
                                <th>Urgent </th>
                                <th>High </th>
                                <th>Normal </th>
                                <th>Low </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Extensive </td>
                                <td>High </td>
                                <td>High </td>
                                <td>Medium </td>
                                <td>Medium </td>
                            </tr>

                            <tr>
                                <td>Significant </td>
                                <td>High </td>
                                <td>Medium </td>
                                <td>Medium </td>
                                <td>Normal </td>
                            </tr>
                            <tr>
                                <td>Moderate </td>
                                <td>Medium </td>
                                <td>Medium </td>
                                <td>Normal </td>
                                <td>Low </td>
                            </tr>
                            <tr>
                                <td>Minor </td>
                                <td>Medium </td>
                                <td>Normal </td>
                                <td>Low </td>
                                <td>Low </td>
                            </tr>
                        </tbody>
                    </table>
                    <h6 class="fs-6 mb-2"><b>Service Level Agreement (SLA) defined according to the Priority, Impact and
                            Urgency: </b></h6>
                    <table class="w-100 ">
                        <thead>
                            <tr>
                                <th>Priority </th>
                                <th>Response Time </th>
                                <th>Resolution Time </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>High </td>
                                <td>15mins </td>
                                <td>30mins </td>
                            </tr>

                            <tr>
                                <td>Medium </td>

                                <td>30mins </td>
                                <td>45mins </td>
                            </tr>
                            <tr>
                                <td>Normal</td>

                                <td>45mins</td>
                                <td>1hour</td>
                            </tr>
                            <tr>
                                <td>Low </td>

                                <td>1hour </td>
                                <td>3hour </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="text-black fs-6"><b>Note: </b></p>
                    <p class="fs-6">Comhard has mentioned priority on their portal, based on above calculations </p>
                    <p class="fs-6 text-black">All response and resolution times are applicable only within Comhard's
                        business hours. Delays
                        caused by customer non-response or availability will not be counted towards the resolution time.
                        The SLA is counted when the case is OPEN with us and is applicable only when customers raise requests through the CoCloud portal.</p>
                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>5. BUSINESS HOURS </b></h6>
                    <div class="ms-5">
                        <p class="fs-6"><b>a. </b>Monday to Saturday: 10:00 AM to 10:00 PM IST </p>
                        <p class="fs-6"><b>b. </b>Sunday: 10:00 AM to 07:00 PM IST </p>

                    </div>
                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>6. DOWNTIME REPORTING AND REBATES </b></h6>
                    <div class="ms-5">
                        <p class="fs-6"><b>6.1 </b>Customers are required to report any Downtime to Comhard within 24
                            hours of discovering it. Reports should be sent via email from the registered email ID to <a
                                href="mailto:support@comhard.co.in. ">support&#64;comhard.co.in</a>. </p>
                        <p class="fs-6"><b>6.2 </b>Upon receiving such reports, Comhard will promptly investigate the
                            reported Downtime and make reasonable efforts to rectify it. Failure to adhere to the
                            reporting guidelines will result in the email not being considered a valid Downtime report.
                        </p>
                        <p class="fs-6"><b>6.3 Eligibility for Rebates: </b></p>
                        <p class="fs-6">Rebates are only applicable to Downtime for which Comhard's support team has
                            been notified by the Customer. </p>
                        <p class="fs-6">Rebates are calculated per incident and as a percentage of availability over a
                            billed month. Downtime periods are not aggregated for Rebate calculation. </p>
                        <p class="fs-6">Customers must request Rebates by sending an email to support&#64;comhard.co.in
                            with the subject "SLA Rebate Request," providing details of the reported Downtime.</p>
                        <p class="fs-6">
                            Failure to request Rebates within 2 days of the end of the billed month for which they are
                            due will result in the waiver of Rebates for that Downtime.
                        </p>
                        <p class="fs-6">Rebates can be applied to future invoices and do not entitle Customers to a
                            refund. </p>
                    </div>

                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>7. EXCEPTIONS</b></h6>
                    <p class="text-color fs-6">Comhard shall not be held responsible for Downtime resulting from: </p>
                    <div class="ms-5">
                        <p class="fs-6"><b>a. Customer-Requested Service Modifications: </b>Downtime resulting from
                            changes or alterations made to the Services at the specific request of the Customer will not
                            be considered. </p>
                        <p class="fs-6"><b>b. Customer Software and Third-Party Services: </b>Any interruptions arising
                            from defects or failures in the Customer's own software or the use of third-party services
                            or facilities procured or operated by the Customer will not be attributed to Comhard. This
                            includes third-party Open Source Software or Software Licenses provided by Comhard. </p>
                        <p class="fs-6"><b>c. Incomplete or Inaccurate Information: </b>Downtime caused by incomplete or
                            inaccurate information provided by the Customer regarding the Services or information
                            relevant to creating a Customer account will not be considered Comhard's responsibility.
                        </p>
                        <p class="fs-6"><b>d. Third-Party Internet Networks: </b>Interruptions or Downtime caused by the
                            performance of traffic exchange points, Internet networks, or exchanges controlled by third
                            parties are beyond Comhard's control and will not be counted as Service Downtime. </p>
                        <p class="fs-6"><b>e. Customer Obligations: </b>Downtime resulting from delays or failures in
                            the Customer's compliance with their obligations under the Terms of Service and/or Master
                            Services Agreement (MSA) will not be considered Comhard's responsibility. </p>
                        <p class="fs-6"><b>f. DNS Issues Outside Comhard's Control: </b>DNS (Domain Name System) issues
                            that are beyond Comhard's direct control and do not originate from its infrastructure will
                            not be counted as Downtime. </p>
                        <p class="fs-6"><b>g. Customer Network and Links: </b>Downtime arising from failures in Customer
                            links, access circuits, local loops, firewalls, routers or any network not owned or operated
                            by Comhard is outside the scope of Comhard's responsibility. </p>
                        <p class="fs-6"><b>h. Offline Backups: </b>Downtime caused by the time taken during offline
                            backups, whether planned or requested by the Customer after advance notice by Comhard, will
                            not be attributed to Comhard. </p>
                        <p class="fs-6"><b>i. Customer-Induced Equipment Damage: </b>Downtime resulting from damage to
                            or faults in equipment that facilitate access to the Services due to accidents,
                            transportation issues, neglect, or misuse by the Customer or their authorized
                            representatives will not be considered Comhard's responsibility. </p>
                        <p class="fs-6"><b>j. Misuse of Data Center Services: </b>Downtime caused by the misuse of data
                            center services by the Customer for purposes unrelated to accessing the Services is beyond
                            Comhard's responsibility. </p>
                        <p class="fs-6"><b>k. Customer Neglect to Notify: </b>Downtime due to the Customer's failure to
                            notify Comhard about unexpected Downtime through the support email provided will not be
                            counted against Comhard. </p>
                        <p class="fs-6"><b>l. "No Trouble Found" Support Requests: </b>Downtime incidents where a
                            support request results in a determination of "no trouble found," as confirmed by the
                            Customer, will not be considered Comhard's responsibility. </p>
                        <p class="fs-6"><b>m. Customer's Failure to Release Service: </b>Downtime resulting from an
                            interruption where the Customer chooses not to release a Service for testing and repair and
                            continues to use it on an impaired basis without notifying Comhard, will not be attributed
                            to Comhard. </p>
                        <p class="fs-6"><b>n. Customer-Induced Errors and Negligence: </b>Downtime arising from
                            Customer-induced errors, non-compliant use of software, incorrect resource sizing,
                            negligence, or other conduct of the Customer or its authorized personnel, including failures
                            or malfunctions resulting from applications or services provided by the Customer, will not
                            be considered Comhard's responsibility. </p>
                        <p class="fs-6"><b>o. Regulatory Events: </b>Downtime caused by regulatory events that lead to
                            Service interruptions will not be counted against Comhard. </p>
                        <p class="fs-6"><b>p. Abuse, Fraud, or Non-Compliance: </b>Downtime resulting from Customer
                            abuse, fraud, or non-compliance with the "Terms of Service or MSA, as applicable," for which
                            the Customer is liable, will not be attributed to Comhard. </p>
                        <p class="fs-6"><b>q. Factors Beyond Comhard's Control: </b>Downtime or unavailability caused by
                            factors outside Comhard's reasonable control, including Force Majeure Events or internet
                            access-related problems beyond Comhard's control or beyond the scope of the Services, will
                            not be considered Comhard's responsibility. </p>
                        <p class="fs-6"><b>r. Cyber Threats and Security Incidents: </b>Downtime resulting from cyber
                            threats, such as Distributed Denial of Service (DDoS) attacks, ransomware, malware, or other
                            cyber incidents, including those impacting the security and performance of Comhard's
                            infrastructure or third-party services used by Comhard, will not be counted as Service
                            Downtime if caused by factors outside Comhard's control. This also includes security-related
                            incidents that necessitate urgent security updates or measures to safeguard the
                            infrastructure and data </p>
                    </div>
                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>8. CALCULATION OF REBATES </b></h6>

                    <div class="ms-5">
                        <p class="fs-6">The calculation of applicable credits for Rebates will commence from the time
                            Comhard's Support is notified by email and conclude upon the resolution of the reported
                            outage. </p>
                    </div>
                </div>
                <div class="mt-3">
                    <h6 class="fs-6 mb-2"><b>9. DATA INTEGRITY AND SECURITY </b></h6>

                    <div class="ms-5">
                        <p class="fs-6">Comhard does not assume responsibility for data integrity and security. It is
                            the
                            Customers' responsibility to implement appropriate security measures, safeguard passwords
                            and
                            security keys, and manage data backup and recovery plans. </p>
                    </div>
                    <div class="mt-3">
                        <h6 class="fs-6 mb-2"><b>10. SOLE REMEDY </b></h6>
                        <div class="ms-5">
                            <p class="fs-6">Unless explicitly stated in the Terms of Service, this SLA constitutes the
                                sole and
                                exclusive remedies available to Customers and the sole obligations of Comhard for any
                                unavailability, non-performance, or other failures related to Service provision. </p>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h6 class="fs-6 mb-2"><b>11. MODIFICATIONS TO SLA </b></h6>
                        <div class="ms-5">
                            <p class="fs-6">Comhard retains the right to modify this SLA at any time by publishing a
                                revised
                                version on its website. Customers are bound by the amended SLA if they continue to use
                                the
                                Services.
                                By utilizing Comhard's Cloud services, Customers acknowledge and agree to the terms
                                outlined in
                                this SLA, recognizing its legally binding nature. </p>
                        </div>
                    </div>
                    <div class="mt-3">
                        <p class="fs-6"><b>Last Updated:</b> 19<sup>th</sup> September 2024 </p>
                        <p class="fs-6"><b>Effective from: </b>23<sup>rd</sup> September 2024 </p>
                    </div>
                </div>

            </div>
        </div>
    </div>