import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VerifyMobileComponent } from '../modals/verify-mobile/verify-mobile.component';
import { SubFilterPipe } from '../customPipe/sub-filter.pipe';
import { RaiseRequestService } from 'src/app/services/ticket.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-raise-service-request',
  templateUrl: './raise-service-request.component.html',
  styleUrls: ['./raise-service-request.component.scss'],
  providers: [SubFilterPipe]
})
export class RaiseServiceRequestComponent implements OnInit {



  issueFormControl = new FormGroup({
    subscriptionId: new FormControl('', [Validators.required]),
    issueType: new FormControl('', [Validators.required]),
    issueSubType: new FormControl('', [Validators.required]),
    issueSubSubType: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    searchText: new FormControl('')
  })

  selectedCategory: any
  subCategories: any
  categories: any
  selectedIssueType: string = ""
  subCategorySelected: boolean = false
  subSubSelected: boolean = false
  bsModalRef!: BsModalRef;
  subCodeSelected: boolean = false
  subCodes: any;
  selectedSub: String = ""

  constructor(
    private raiseRequestService: RaiseRequestService,
    private location: Location,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.subList()
    this.categoryList()
  }

  get searchText() {
    return this.issueFormControl.controls.searchText.value || '';
  }

  subList() {
    this.raiseRequestService.ticketSubList(false).subscribe((res: any) => {
      if (res.success) {
        this.subCodes = res.subCodes
      }
    })
  }

  categoryList() {
    this.raiseRequestService.categoryList().subscribe((res: any) => {
      if (res.success) {
        this.categories = res.categories
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  selectedIssue(type: string) {
    this.selectedIssueType = type;
    this.subSubSelected = false;
    this.subCategorySelected = false;
    this.subCodeSelected = false;
    this.issueFormControl.reset()
    const subject = (type === 'tech') ? 'Technical Issue' : (type === 'bill') ? 'Billing & Payment' : 'Co-Coloud Thanks for Business'
    this.issueFormControl.controls.issueType.setValue(subject)
    if (type != 'tech') {
      this.issueFormControl.controls.issueSubType.clearValidators();
      this.issueFormControl.controls.issueSubType.updateValueAndValidity();
      this.issueFormControl.controls.issueSubSubType.clearValidators();
      this.issueFormControl.controls.issueSubSubType.updateValueAndValidity();
      this.issueFormControl.controls.subscriptionId.clearValidators();
      this.issueFormControl.controls.subscriptionId.updateValueAndValidity();
    }
  }

  subCategoryList() {
    const category = this.issueFormControl.controls.issueSubType.value || ''
    this.selectedCategory = this.categories.find((obj: any) => obj.name == category)
    this.subCategorySelected = true
    this.raiseRequestService.subCategoryList(this.selectedCategory.id).subscribe((res: any) => {
      if (res.success) {
        this.subCategories = res.subcategories
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  subCategory() {
    this.subSubSelected = true
  }

  selectedSubId(subId: string, code: string) {
    this.subCodeSelected = true
    this.selectedSub = code
    this.issueFormControl.controls.subscriptionId.setValue(subId)
  }

  submit() {
    const issue = this.issueFormControl.value
    const selectedSuCategory = this.subCategories.find((obj: any) => obj.name == issue.issueSubSubType)
    const payload = {
      subject: this.issueFormControl.value.issueType,
      issueSubType: this.issueFormControl.value.issueSubType,
      issueSubSubType: this.issueFormControl.value.issueSubSubType,
      description: this.issueFormControl.value.description,
      subscriptionId: this.issueFormControl.value.subscriptionId,
      categoryId: this.selectedCategory.id,
      subCategoryId: selectedSuCategory.id
    }
    this.bsModalRef = this.modalService.show(VerifyMobileComponent, {
      class: 'modal-sm',
      backdrop: 'static',
    });
    this.bsModalRef.content.payload = payload;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.router.navigate(['ticket-to-cocloud']);
      }
    });

  }

  cancel() {
    this.location.back();
  }
  handleFileChange(fileInput: any) {
    if (fileInput.files[0]) {
      const file: File = fileInput.files[0];
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        this.toastr.warning("Invalid Format")
        return;
      }
    }
  }
}
