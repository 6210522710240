<div class="container-fluid ps-0 pe-0">
    <div class="main-panel">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-body class-padding">
                        <h6 class="heading text-center ">PRIVACY POLICY</h6>
                        <div class="row mt-4">
                            <p class="text mb-3">
                                At Comhard Technologies Private Limited, safeguarding the privacy of our website
                                visitors, as well as those who respond to our advertisements or communicate via email,
                                is a top priority. This Privacy Policy elucidates the collection and utilization of
                                information to provide clarity on when and how personal data is acquired, used,
                                disclosed, and protected.<br /> <br />

                                For services powered by Comhard Technologies Private Limited, such as CoCloud, offering
                                application on Cloud Services, users are encouraged to review and adhere to this Privacy
                                Policy.
                            </p>

                            <p class="sub-heading mb-3">
                                Network Security
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies employs a comprehensive set of physical, electronic, and procedural
                                safeguards, coupled with robust encryption technologies, to fortify the protection of
                                your personal information. This security framework extends across various interactions,
                                such as viewing bills or requesting information about our services. <br />

                                It is essential to note that while we ensure the security of your information within our
                                systems, we do not have control over the security measures implemented by other websites
                                on the internet. To prevent unauthorized access to your account information, maintaining
                                the confidentiality of your username, password, and other access credentials is
                                imperative.
                            </p>
                            <p class="sub-heading mb-3">
                                Personal Information
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies refrains from collecting personal information (such as name,
                                address, telephone number, email address, etc.) unless voluntarily provided by the user.
                                We do not share, disclose, or sell any personal information collected through
                                comhard.co.in with unaffiliated companies. Additionally, the disclosure of Customer
                                Proprietary Network Information (CPNI) is restricted for purposes related to providing
                                or marketing our products and services. In the context of a merger, sale, or corporate
                                reorganization, personal information would be treated as a valuable asset.
                            </p>
                            <p class="sub-heading mb-3">
                                How We Use Collected Information
                            </p>
                            <p class="text mb-3">
                                The information collected by Comhard Technologies is utilized to fulfill the services
                                you've ordered and to inform you about other offerings that may align with your
                                interests. This includes communication regarding updates, seamless provisioning and
                                billing of services, and addressing customer inquiries. <br />

                                While Comhard Technologies prioritizes the protection of user information, it may
                                disclose personally-identifiable information in response to legal demands or when
                                necessary to identify, contact, or take legal action against individuals posing threats
                                to public safety or interfering with our property or services.
                            </p>
                            <p class="sub-heading mb-3">
                                Internal Business Use 
                            </p>
                            <p class="text mb-3">
                                We leverage personal information for various aspects of our business operations, including service delivery, identifying customers, billing, creditworthiness assessments, tax compliance, protecting our rights, and communicating about accounts and services. This ensures a smooth and secure interaction with our customers. 
                            </p>
                            <p class="sub-heading mb-3">
                                Marketing Communications  
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies may use personal information to inform users about new products and services via various communication channels. Users have the option to opt out of receiving marketing information while still receiving essential notifications related to invoices, customer service, technical or network updates, and similar information.
                            </p>
                            <p class="sub-heading mb-3">
                                Content   
                            </p>
                            <p class="text mb-3">
                                All hosting services provided by Comhard Technologies are to be used for lawful purposes. Transmission, storage, or presentation of any information in violation of Indian law is strictly prohibited. This includes copyrighted materials, trade secrets, obscene content, and materials contrary to public interest or policy.<br/> 
                                Prohibited content includes pornographic materials and sex-related merchandising. Comhard Technologies reserves the right to determine violations of this provision and take appropriate action. 
                            </p>
                            <p class="sub-heading mb-3">
                                Materials and Products 
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies exercises no control over the content of customer networks or websites. We do not provide warranties or guarantees, expressed or implied, for the services offered. The responsibility for the accuracy and quality of information obtained through Comhard Technologies lies with the customer. <br/>
                                Any violation of this policy should be reported to Comhard Technologies. Connection speed represents the connection to Comhard Technologies and does not guarantee end-to-end bandwidth. 
                            </p>
                            <p class="sub-heading mb-3">
                                Data Collection and Use
                            </p>
                            <p class="text">When you use our Google Drive backup service, we may collect the following information:</p>
                            <!-- <p class="text mb-3">
                                Files and data you choose to back up from your cloud machine.
                                Metadata related to your files (e.g., file names, sizes).
                                We use this information solely to provide and improve our backup services.
                            </p> -->
                            <ul class="text ">
                                <li><b><i class="bi bi-dot"></i></b> Files and data you choose to back up from your cloud machine.</li>
                                <li><b><i class="bi bi-dot"></i></b> Metadata related to your files (e.g., file names, sizes).</li>
                            </ul>
                            <p class="text mb-3"> We use this information solely to provide and improve our backup services. Apart from this, we do not use any of your personal data.</p>
                            <p class="sub-heading mb-3">
                                Backup Process
                            </p>
                            <p class="text mb-3">
                                Our service sends a backup of the data on your cloud machine to your Google Drive on a daily basis. This ensures that you have a regular and up-to-date copy of your data securely stored.
                            </p>
                            <p class="sub-heading mb-3">
                                Data Retention
                            </p>
                            <p class="text mb-3">
                                You have the right to set the retention period for your backups during the scheduler creation. We retain backups of your data in Google Drive for the duration of the retention period you specify. Once this period expires, we automatically delete the backup copies from Google Drive to help you save space and manage your storage more efficiently.
                            </p>
                            <p class="sub-heading mb-3">
                                Data Deletion
                            </p>
                            <p class="text mb-3">
                                After the retention period you have specified expires, the backup copies of your data will be permanently deleted from your Google Drive. This process ensures that your data is not stored longer than necessary and that you have the maximum available space in your Google Drive account. Before the retention period ends, we do not delete any of your data copies in Google Drive.
                            </p>
                            <p class="sub-heading mb-3">
                                Security
                            </p>
                            <p class="text mb-3">
                                We employ industry-standard security measures to protect your data during transmission and storage. However, we cannot guarantee absolute security, and you should also take measures to protect your own account and data.
                            </p>
                            <p class="sub-heading mb-3">
                                Access to Your Data
                            </p>
                            <p class="text mb-3">
                                You have control over the data you back up with our service. You can access, modify, or delete your backup settings at any time through your account dashboard.
                            </p>
                            <p class="sub-heading mb-3">
                                Prohibited Activities 
                            </p>
                            <p class="text mb-3">
                                Misuse, including illegal activities or unauthorized access, is strictly forbidden.
                            </p>
                            <p class="sub-heading mb-3">
                                Consequences
                            </p>
                            <p class="text mb-3">
                                Misuse will result in immediate termination of the backup process and possible account suspension.
                            </p>
                            <p class="sub-heading mb-3">
                                Changes to this Privacy Policy 
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies retains the right to modify this Privacy Policy at its discretion without notice. Amendments are effective upon posting, and continued use of our services constitutes acceptance of the changes.  
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>