import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { TokenInterceptorService } from './_helpers/token-interceptor.service';
import { LoginWithAdminComponent } from './login-with-admin/login-with-admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from './_helpers/loading.interceptor';
import { IntercomModule } from 'ng-intercom';
import { environment } from 'src/environments/environment';
import { VerifyPaymentComponent } from './verify-payment/verify-payment.component';
import { FooterComponent } from './footer/footer.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SlaComponent } from './sla/sla.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginWithAdminComponent,
    SpinnerComponent,
    VerifyPaymentComponent,
    FooterComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    SlaComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    })

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
