import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { SharedComponent } from './shared.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from '../_helpers/token-interceptor.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddAccountComponent } from './account/add-account/add-account.component';
import { AccountComponent } from './account/account.component';
import { AuditComponent } from './audit/audit.component';
import { BillingComponent } from './billing/billing.component';
import { BrandingComponent } from './branding/branding.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlanComponent } from './plan/plan.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingComponent } from './setting/setting.component';

import { PartnerDetailsComponent } from './account/partner-details/partner-details.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { AddPlanComponent } from './plan/add-plan/add-plan.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalsComponent } from './modals/modals.component';
import { AddLabelModalComponent } from './modals/add-label-modal/add-label-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageUsersComponent } from './modals/manage-users/manage-users.component';
import { EditExpiryDateComponent } from './modals/edit-expiry-date/edit-expiry-date.component';
import { ManageLabelComponent } from './plan/manage-label/manage-label.component';
import { ChangePlanComponent } from './modals/change-plan/change-plan.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { NewSubscriptionsComponent } from './subscriptions/new-subscriptions/new-subscriptions.component';
import { ProductComponent } from './product/product.component';
import { SubscriptionPreviewComponent } from './modals/subscription-preview/subscription-preview.component';
import { PlanAndAddOnComponent } from './product/plan-and-add-on/plan-and-add-on.component';
import { AddSubscriptionComponent } from './subscriptions/add-subscription/add-subscription.component';
import { SubscriptionListComponent } from './subscriptions/subscription-list/subscription-list.component';
import { SubscriptionSelectComponent } from './modals/subscription-select/subscription-select.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChangeCustomerComponent } from './modals/change-customer/change-customer.component';
import { ChangeFreeTrialComponent } from './modals/change-free-trial/change-free-trial.component';
import { FilterPipe } from './customPipe/filter.pipe';
import { InstanceUserDetailsComponent } from './instance-user-details/instance-user-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { SalesManComponent } from './sales-man/sales-man.component';
import { RolesPermissionsComponent } from './roles-permissions/roles-permissions.component';
import { AddSalesmanComponent } from './sales-man/add-salesman/add-salesman.component';
import { AddRolesComponent } from './roles-permissions/add-roles/add-roles.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { ChangeSalesmanComponent } from './modals/change-salesman/change-salesman.component';
import { LogoutComponent } from './modals/logout/logout.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { PaymentComponent } from './payment/payment.component';
import { RaiseServiceRequestComponent } from './raise-service-request/raise-service-request.component';
import { AllServiceRequestComponent } from './all-service-request/all-service-request.component';
import { SubscriptionsDetailsComponent } from './subscriptions/subscriptions-details/subscriptions-details.component';
import { PlanUpgradeDowngradeComponent } from './modals/plan-upgrade-downgrade/plan-upgrade-downgrade.component';
import { RenewConfirmationComponent } from './modals/renew-confirmation/renew-confirmation.component';
import { CrmComponent } from './subscriptions/crm/crm.component';
import { ActivityComponent } from './activity/activity.component';
import { VerifyMobileComponent } from './modals/verify-mobile/verify-mobile.component';
import { SubFilterPipe } from './customPipe/sub-filter.pipe';
import { ExpireComponent } from './modals/expire/expire.component';
import { PricePipePipe } from './customPipe/price-pipe.pipe';
import { AssignSubsToSalespersonComponent } from './modals/assign-subs-to-salesperson/assign-subs-to-salesperson.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import { HighchartsChartModule } from 'highcharts-angular';
import { AssginUserComponent } from './modals/assgin-user/assgin-user.component';
import { ChangePriceComponent } from './modals/change-price/change-price.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { CustomerTicketComponent } from './customer-ticket/customer-ticket.component';
import { LicenseDetailsComponent } from './license-details/license-details.component';
import { MrrReportComponent } from './mrr-report/mrr-report.component';
import { SalespersonReportComponent } from './salesperson-report/salesperson-report.component';
import { RelationshipMangerReportComponent } from './relationship-manger-report/relationship-manger-report.component';
import { ReasonForChurnComponent } from './modals/reason-for-churn/reason-for-churn.component';
import { BecomePartnerComponent } from './modals/become-partner/become-partner.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { CreateOrderComponent } from './order-management/create-order/create-order.component';
import { GoogleDriveComponent } from './google-drive/google-drive.component';
// ----------------------------

@NgModule({
  declarations: [
    SharedComponent,
    HeaderComponent,
    AccountComponent,
    AddAccountComponent,
    AuditComponent,
    BillingComponent,
    BrandingComponent,
    DashboardComponent,
    PlanComponent,
    ReportsComponent,
    SettingComponent,
    PartnerDetailsComponent,
    DeleteModalComponent,
    AddPlanComponent,
    ModalsComponent,
    AddLabelModalComponent,
    ModalsComponent,
    ManageUsersComponent,
    EditExpiryDateComponent,
    ManageLabelComponent,
    ChangePlanComponent,
    SubscriptionsComponent,
    NewSubscriptionsComponent,
    ProductComponent,
    SubscriptionPreviewComponent,
    PlanAndAddOnComponent,
    AddSubscriptionComponent,
    SubscriptionListComponent,
    SubscriptionSelectComponent,
    ChangeCustomerComponent,
    ChangeFreeTrialComponent,
    FilterPipe,
    InstanceUserDetailsComponent,
    UserDetailsComponent,
    SalesManComponent,
    RolesPermissionsComponent,
    AddSalesmanComponent,
    AddRolesComponent,
    AuditLogComponent,
    ChangeSalesmanComponent,
    LogoutComponent,
    BillingDetailsComponent,
    PaymentComponent,
    RaiseServiceRequestComponent,
    AllServiceRequestComponent,
    SubscriptionsDetailsComponent,
    PlanUpgradeDowngradeComponent,
    RenewConfirmationComponent,
    CrmComponent,
    ActivityComponent,
    VerifyMobileComponent,
    SubFilterPipe,
    ExpireComponent,
    PricePipePipe,
    AssignSubsToSalespersonComponent,
    AssginUserComponent,
    ChangePriceComponent,
    TicketDetailsComponent,
    CustomerTicketComponent,
    LicenseDetailsComponent,
    MrrReportComponent,
    SalespersonReportComponent,
    RelationshipMangerReportComponent,
    ReasonForChurnComponent,
    BecomePartnerComponent,
    OrderManagementComponent,
    CreateOrderComponent,
    GoogleDriveComponent

  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    ModalModule,
    NgbModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDividerModule,
    MatCheckboxModule,
    HighchartsChartModule,
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-right'
      }
    ),
    NgxPaginationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    BsModalService

  ]
})
export class SharedModule { }
